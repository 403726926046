























































































































import { namespace } from 'vuex-class';
import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { FormComponent, VForm } from '@/types/v-form';

import FirmStructureTree from '@/components/utils/FirmStructureTree.vue';

import Risk from '@models/proRisk/Risk';
import FirmStructure from '@models/firm/FirmStructure';
import RiskCharacter from '@models/proRisk/RiskCharacter';

import RiskForm from '@modules/professional-risk/form/RiskForm';
import RequestSaveForm from '@/utils/RequestSaveForm';

import * as Rules from '@/utils/CustomFormRules';
import _ from 'lodash';

const firmModule = namespace('firm');

const riskRepository = namespace('repositories/risk');
const riskCharacterRepository = namespace('repositories/riskCharacter');

@Component({
  components: {
    FirmStructureTree,
  },
})
export default class ProRiskForm extends Vue implements FormComponent {
  @firmModule.Getter('getOrganization')
  structure!: FirmStructure[];

  @riskRepository.Action('insertOrUpdate')
  save!: (risk: RiskForm) => Promise<void>;

  @riskCharacterRepository.Getter('getAll')
  riskCharacters!: Array<RiskCharacter>;

  @riskCharacterRepository.Action('findAll')
  fetchRiskCharacter!: () => void;

  @Prop()
  risk!: Risk;

  loading: boolean = false;
  isModify: boolean = true;

  $refs!: {
    form: VForm,
  };

  riskForm: RiskForm | null = null;

  get riskCharacterSorted() {
    if (this.riskCharacters) {
      return _.orderBy(this.riskCharacters, [rc => rc.label.toLowerCase()], ['asc']);
    }
    return [];
  }

  get rules() {
    return {
      characterId: [
        { required: true, message: 'Cette valeur ne doit pas être vide.', trigger: 'blur' },
      ],
      structures: [
        {
          message: 'Veuillez renseigner une affectation',
          trigger: 'blur',
          validator: Rules.validateArray,
        },
      ],
    };
  }

  @Watch('risk', { deep: true, immediate: true })
  onRiskChange(n: Risk | null, o: Risk) {
    if (n !== null) {
      this.riskForm = new RiskForm(n);
    }
  }

  created() {
    this.fetchRiskCharacter();
  }

  async valid(): Promise<any> {
    let validForm = false;
    let errorFields = {};
    this.$refs.form.validate((valid, errors) => {
      validForm = valid;
      errorFields = errors;
    });

    if (this.riskForm !== null && validForm) {
      this.loading = true;
      return this.saveForm(this.riskForm).finally(() => {
        this.loading = false;
      });
    }

    return Promise.reject();
  }

  @RequestSaveForm({
    validMsg: 'Mise à jour effectuée. Pensez à (ré)enregistrer le plan d\'action si les assignations ont changé.',
    errorMsg: 'Erreur lors de la sauvegarde de votre risque.',
  })
  async saveForm(riskForm: RiskForm) {
    return this.save(riskForm).then((response) => {
      this.$emit('valid');
      return response;
    });
  }
}
