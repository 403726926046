import Risk from '@models/proRisk/Risk';
import { Expose } from 'class-transformer';

export default class RiskForm {
  constructor(risk: Risk) {
    this.id = risk.id;
    this.description = risk.description;
    this.organizationalArrangements = risk.organizationalArrangements;
    this.individualArrangements = risk.individualArrangements;
    this.collectiveArrangements = risk.collectiveArrangements;
    this.evrp = risk.evrp;
    this.characterId = risk.characterId;
    this.folderId = risk.folder ? risk.folder.id : risk.folderId;
    this.structures = risk.structures.map(structure => structure.id as number);
  }

  @Expose({ groups: ['patch'] })
  id!: number;
  @Expose({ groups: ['post', 'patch'] })
  description!: string;
  @Expose({ groups: ['post', 'patch'] })
  organizationalArrangements!: string;
  @Expose({ groups: ['post', 'patch'] })
  individualArrangements!: string;
  @Expose({ groups: ['post', 'patch'] })
  collectiveArrangements!: string;
  @Expose({ groups: ['post', 'patch'] })
  evrp!: string;

  @Expose({ name: 'character' })
  characterId!: number | null;

  @Expose({ name: 'folder' })
  folderId!: number | null;

  @Expose({ groups: ['post', 'patch'] })
  structures!: Array<number>;
}
